import React, { useEffect, useState, useRef } from 'react';
import { Typography, IconButton, Input, Textarea, Avatar } from '@material-tailwind/react';
import botImage from './assets/images/bot.png'; // Zorg ervoor dat dit pad correct is

export function ChatScreen({ onClose }) {
    const [input, setInput] = useState('');
    const [messages, setMessages] = useState([]);
    const [threadId, setThreadId] = useState('');
    const [loading, setLoading] = useState(false);
    const messagesEndRef = useRef(null);  // Ref voor het automatisch scrollen
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

    useEffect(() => {
        // Laden van berichten en threadId uit lokale opslag bij het openen van het component
        const chatData = localStorage.getItem('chatBotMessages');
        if (chatData) {
            const parsedData = JSON.parse(chatData);
            setMessages(parsedData.messages);
            setThreadId(parsedData.threadId || ''); // Veiligstellen dat er een fallback is als threadId niet bestaat
        }
    }, []);

    useEffect(() => {
        const checkIfMobile = () => {
            setIsMobile(window.innerWidth < 1024);
        };

        window.addEventListener('resize', checkIfMobile);

        return () => window.removeEventListener('resize', checkIfMobile);
    }, []);

    useEffect(() => {
        // Opslaan van berichten en threadId in lokale opslag wanneer deze veranderen
        const chatData = { messages, threadId };
        localStorage.setItem('chatBotMessages', JSON.stringify(chatData));
    }, [messages, threadId]);

    const handleInputChange = (event) => {
        setInput(event.target.value);
    };

    const deleteConversation = () => {
        setMessages([]);
        setInput('');
        setThreadId('');
        localStorage.removeItem('chatBotMessages');
    }

    useEffect(() => {
        // Scroll naar het einde van de berichtenlijst wanneer berichten worden toegevoegd
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    // Definitie van getChatbotWebhook binnen het component
    const sendMessageToBot = async (input) => {
        setLoading(true);

        const companyId = 'XXX';

        let body = JSON.stringify({
            companyId: companyId,
            message: input,
            thread_id: threadId
        });

        const getChatbotWebhook = 'https://hook.eu2.make.com/p9atx6gvs0hrdad494p5vn2vkym6lhxi';

        try {
            const response = await fetch(getChatbotWebhook, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: body,
            });
            const data = await response.json(); // Verwerk de response als JSON
            setLoading(false);
            return data;
        } catch (error) {
            console.error('Error fetching from webhook:', error);
            return { bot: 'Er was een fout bij het ophalen van de gegevens', thread_id: threadId };
        }
    }

    const sendMessage = async () => {
        if (input.trim() === '') return;

        const userMessage = { sender: 'user', text: input };
        setMessages(messages => [...messages, userMessage]);
        setInput(''); // Reset input field

        const responseData = await sendMessageToBot(input);
        const botMessage = { sender: 'bot', text: responseData.bot };

        // Update de berichtenlijst en de thread ID
        setMessages(messages => [...messages, botMessage]);
        setThreadId(responseData.thread_id);  // Sla de nieuwe thread_id op
    };

    return (
        <div id="chatBot" className="fixed bottom-16 right-9 bg-white rounded-lg shadow-xl p-4 z-50 border dark:bg-[#171B1C] dark:border-black " style={{ width: isMobile ? "320px" : "400px", height: isMobile ? "650px" : "700px" }}>
            <div className="flex justify-between items-start border-b-2 mb-2">
                <div className='flex flex-col gap-2'>
                    <div className='flex gap-2 items-center'>
                        <Avatar src={botImage} alt="avatar" size="xs" />
                        <Typography variant='h4' className='dark:text-gray-200'>
                            Jeb</Typography>
                    </div>
                    <Typography variant='small' className='dark:text-gray-400'>Ask Jeb a question</Typography>
                </div>
                <div className='flex gap-4'>
                    <IconButton color="blue-gray" variant='text' onClick={deleteConversation} className="text-lg font-semibold"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                    </svg>
                    </IconButton>
                    <IconButton color="blue-gray" variant='text' onClick={onClose} className="text-lg font-semibold">&#x2715;</IconButton>
                </div>
            </div>

            <ul className="overflow-hidden overflow-y-scroll h-[80%] relative flex flex-col gap-2">
                {messages.map((message, index) => (
                    <li key={index} className={`m-2 p-3 whitespace-pre-wrap break-words max-w-72 rounded-lg  ${message.sender === 'user' ? 'self-end bg-gray-100 rounded-tr-none dark:bg-gray-800 dark:text-gray-200' : 'self-start bg-blue-500 text-white w-auto rounded-tl-none'}`}>
                        {message.text}
                    </li>
                ))}
                <div ref={messagesEndRef} />

                {loading && (
                    <div className="loader-dots m-4">
                        <div className="loader-dot"></div>
                        <div className="loader-dot"></div>
                        <div className="loader-dot"></div>
                    </div>
                )}
            </ul>

            <div className="flex mt-4">
                <input
                    type="text"
                    value={input}
                    disabled={loading}
                    onChange={handleInputChange}
                    className="flex-1 p-2 border-2 rounded rounded-tr-none rounded-br-none h-10 dark:bg-black dark:border-black dark:text-gray-200"
                    onKeyPress={event => event.key === 'Enter' ? sendMessage() : null}
                />
                <IconButton onClick={sendMessage}
                    disabled={loading}
                    className="left-[-5px] px-5 bg-blue-500 text-white rounded-tl-none rounded-bl-none">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
                    </svg>
                </IconButton>
            </div>
        </div>
    );
}
