import React, { useState, useEffect } from 'react';
import { IconButton, Typography } from "@material-tailwind/react";
import { ChatScreen } from './ChatScreen'; // Importeer je ChatScreen component hier

function App() {
  const [chatOpen, setChatOpen] = useState(false); // State om het chatvenster te tonen/verbergen
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  const toggleChat = () => {
    setChatOpen(!chatOpen); // Toggle de chat visibiliteit
  };


  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setChatOpen(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  return (
    <div className={`flex flex-col h-screen transition-all duration-100`}>
      <div className={`fixed gap-2 z-50 ${isHovered && 'flex items-center  bg-[#fff] pl-3 rounded-full dark:bg-black shadow-m'} ${isMobile ? 'bottom-2 right-2' : 'bottom-4 right-8'}`}>
        <div
          className={`flex flex-col items-center ${isHovered ? 'max-w-xs' : 'max-w-0 overflow-hidden'}`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Typography variant="h6" className='text-[12px] text-right dark:text-gray-200'>Do you have a question?</Typography>
          <Typography variant="small" color="gray" className="text-[10px] dark:text-gray-200">
            Ask a question here.
          </Typography>
        </div>

        <IconButton
          onClick={toggleChat}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          className="rounded-full"
          color='blue'
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="800"
            height="800"
            viewBox="0 0 24 24"
            className="h-6 w-6"
          >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
              <g fill="#fff" fillRule="nonzero">
                <path d="M17.753 14a2.25 2.25 0 012.25 2.25v.905A3.75 3.75 0 0118.696 20C17.13 21.344 14.89 22.001 12 22.001c-2.89 0-5.128-.657-6.691-2a3.75 3.75 0 01-1.305-2.844v-.907A2.25 2.25 0 016.254 14h11.5zM11.9 2.007L12 2a.75.75 0 01.743.649l.007.101v.75h3.5a2.25 2.25 0 012.25 2.25v4.505a2.25 2.25 0 01-2.25 2.25h-8.5a2.25 2.25 0 01-2.25-2.25V5.75A2.25 2.25 0 017.75 3.5h3.5v-.75a.75.75 0 01.649-.743L12 2l-.101.007zM9.749 6.5a1.25 1.25 0 100 2.499 1.25 1.25 0 000-2.499zm4.493 0a1.25 1.25 0 100 2.499 1.25 1.25 0 000-2.499z"></path>
              </g>
            </g>
          </svg>
        </IconButton>
      </div>

      {chatOpen && <ChatScreen onClose={toggleChat} />}
    </div >
  );
}

export default App;
